import React from 'react';
import styled, { createGlobalStyle } from 'styled-components'
import Header from './Header';
import SEO from './SEO';

const GlobalStyles = createGlobalStyle`
  html {
    --blue: rgb(38, 90, 124);
    --lightBlue: rgb(18, 179, 211);
    --lighterBlue: rgba(18, 179, 211, .3);
    --darkBlue: #151538;
    --black: #333333;
    --white: #fdfdfd;
    --grey: #4c4c4c;
    --gray: var(--grey);
    --lightGrey: #E1E1E1;
    --lightGray: var(--lightGrey);
    --offWhite: #EDEDED;
    --maxWidth: 1000px;
    --bs: 0 12px 24px 0 rgba(0,0,0,0.09);
    --buttonGradient: linear-gradient(45deg, var(--blue), var(--lightBlue) );
    box-sizing: border-box;
    font-size: 62.5%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Palanquin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 0;
    margin: 0;
    background: var(--white);
    line-height: 2;
    font-size: 1.5rem;
    background: var(--lighterBlue);
    @media (max-width: 600px) {
      font-size: 1.4rem;
      text-align: center;
    }
  }
  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }
  h1 {
    font-family: 'Palanquin Dark', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
`;

const InnerStyles = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 8rem;
  @media (max-width: 600px) {
    position: relative;
    top: initial;
  }
`;

export default function Layout({children}) {
  return (
    <React.Fragment>
      <SEO/>
      <GlobalStyles/>
      <Header/>
      <InnerStyles>{children}</InnerStyles>
    </React.Fragment>
  )
}