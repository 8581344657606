import React from "react"
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query);

  const defaults = site.siteMetadata;

  const seo = {
    title: title || defaults.title,
    description: description || defaults.description
  };

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {/* fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link href="https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Palanquin&family=Palanquin+Dark&display=swap" rel="stylesheet"/> 
    </Helmet>
  )
};

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleTemplate
      }
    }
  }
`;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
SEO.defaultProps = {
  title: null,
  description: null
};
