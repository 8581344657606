import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Link } from "gatsby"

const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  align-items: stretch;
  padding: 2rem 14%;
  position: relative;
  z-index: 99;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

export default function Header() {
  return (
    <StyledHeader>
      <Link to="/">
        <StaticImage 
          src="../images/cloudfmWhite.png"
          alt="Cloudfm Logo"
          width={220}  
        />
      </Link>
    </StyledHeader>
  )
}